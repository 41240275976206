import React from 'react';
import { Link, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import theme from '../styles/theme';

function LinkBox(props) {
  const { text, href, sx } = props;
  return (
    <Link
      href={!href ? '/' : href}
      underline="none"
      sx={{
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '24px',
        bgcolor: theme.palette.secondary.main,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '4%',
        paddingBottom: '4%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '5%',
        minHeight: '86px',
        ...sx,
      }}
    >
      <Typography variant="body1">{text}</Typography>
      <StaticImage src='../img/arrow.svg' alt="arrow" style={{ marginLeft: '2%' }} placeholder="blurred" loading="lazy" />
    </Link>
  );
}

export default LinkBox;
