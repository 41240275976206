import React from 'react';
import { Typography, Container } from '@mui/material';
import { graphql, StaticQuery } from 'gatsby';
import FeaturedCard from './FeaturedCard';
import ContentContainer from '../styles/content-container';
import theme from '../styles/theme';

function Featured() {
  return (
    <Container disableGutters maxWidth="false">
      <ContentContainer
        disableGutters
        sx={{
          width: '95%',
          marginTop: '48px',
          marginBottom: '16px',
        }}
      >
        <Typography variant="h2">Featured</Typography>
      </ContentContainer>

      <StaticQuery
        query={graphql`
          query FeatBlog {
            allMarkdownRemark(filter: { frontmatter: { collection: { eq: "blog" }, tags: { in: "Featured" } } }) {
              edges {
                node {
                  frontmatter {
                    tags
                    title
                    path
                    profile_picture
                    summary_text
                    summary_image
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const { edges } = data.allMarkdownRemark;

          if (edges.length < 2) return <div />;
          let first = edges.find((item) => item.node.frontmatter.tags.findIndex((tag) => tag === 'One') >= 0);
          let second = edges.find((item) => item.node.frontmatter.tags.findIndex((tag) => tag === 'Two') >= 0);

          if (first === undefined || second === undefined) {
            first = edges[0];
            second = edges[1];
          }
          return (
            <Container
              disableGutters
              maxWidth="false"
              sx={{
                display: 'flex',
                flexDirection: ['column', 'row', 'column', 'column', 'column'],
                justifyContent: 'center',
                alignItems: ['center', 'flex-start', 'center', 'center', 'center'],
              }}
            >
              <FeaturedCard
                src={first.node.frontmatter.summary_image}
                alt="Summary"
                title={first.node.frontmatter.title}
                href={`/blog/${first.node.frontmatter.path}`}
                summary={first.node.frontmatter.summary_text}
                sx={{ [theme.breakpoints.between('sm', 'md')]: { margin: '0 12px 5% 24px' } }}
                img_sx={{ height: ['150px', '200px', '250px', '350px', '400px'] }}
              />
              <FeaturedCard
                src={second.node.frontmatter.summary_image}
                alt="Summary"
                title={second.node.frontmatter.title}
                href={`/blog/${second.node.frontmatter.path}`}
                summary={second.node.frontmatter.summary_text}
                sx={{ [theme.breakpoints.between('sm', 'md')]: { margin: '0 24px 5% 12px' } }}
                img_sx={{ height: ['150px', '200px', '250px', '350px', '400px'] }}
              />
            </Container>
          );
        }}
      />
    </Container>
  );
}

export default Featured;
