import React from 'react';
import { Container, Typography, useMediaQuery } from '@mui/material';
import ContentContainer from '../styles/content-container';
import theme from '../styles/theme';
import LinkBox from './LinkBox';

function HomeLinkBoxes() {
  return (
    <ContentContainer
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: ['column', 'row', 'row', 'row', 'row'],
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        gap: '32px',
        justifyContent: 'space-between',
        marginBottom: '5%',
      }}
    >
      <Container
        disableGutters
        sx={{
          borderRadius: '16px',
          bgcolor: theme.palette.paladin.card,
          padding: '32px',
          width: ['95%', '90%', '90%', '90%', '90%'],
          justifyContent: 'space-evenly',
        }}
      >
        <Typography variant="h2" sx={{ marginBottom: '6%' }}>
          Governance
        </Typography>
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '90%',
          }}
        >
          <LinkBox href="https://gov.paladin.vote/" text="Forums" />
          <LinkBox href="https://app.paladin.vote/#/hpal" text="Holy PAL (hPAL)" />
          <LinkBox href="https://app.paladin.vote/#/gov" text="Delegation Portal" />
          <LinkBox href="https://snapshot.org/#/palvote.eth" text="Snapshot Voting" />
        </Container>
      </Container>

      <ContentContainer
        disableGutters
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          gap: '32px',
        }}
      >
        <Container
          sx={{
            borderRadius: '16px',
            bgcolor: theme.palette.paladin.card,
            padding: '32px',
            width: '100%',
          }}
        >
          <Typography variant="h2" sx={{ marginBottom: '6%' }}>
            Contribute
          </Typography>
          <LinkBox href="https://discord.gg/gxda4HgYXy" text="Discord" />
        </Container>
        <Container
          sx={{
            borderRadius: '16px',
            bgcolor: theme.palette.paladin.card,
            padding: '32px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h2" sx={{ marginBottom: '6%' }}>
            Trade Pal
          </Typography>
          <LinkBox href="https://app.paraswap.io/#/?network=ethereum" text="Swap on Paraswap" />
          <LinkBox href="https://www.coingecko.com/en/coins/paladin" text="Coingecko" sx={{ marginBottom: '0' }} />
        </Container>
      </ContentContainer>
    </ContentContainer>
  );
}

export default HomeLinkBoxes;
