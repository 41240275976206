import React from 'react';
import { Container, Link } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import ContentContainer from '../styles/content-container';

function HomeInfoBoxes() {
  return (
    <ContentContainer disableGutters sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Container disableGutters sx={{ display: 'flex', flexDirection: ['column', 'row', 'row', 'row', 'row'] }}>
      <Link
          href="/blog"
          sx={{
            borderRadius: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '581px',
            margin: ['16px 0 ', '0 11px 0 0', '0 16px 0 0', '0 16px 0 0', '0 16px 0 0'],
          }}
        >
          <StaticImage src='../img/latestNews.png' alt="Latest News" imgStyle={{ width: '100%', height: 'auto' }} placeholder="blurred" loading="lazy" />
        </Link>
         <Link
          href="/vault"
          sx={{
            borderRadius: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '581px',
            margin: ['16px 0', '0 0 0 11px', '0 0 0 16px', '0 0 0 16px', '0 0 0 16px'],
          }}
        >
          <StaticImage src='../img/knowledgeVault.png' alt="Knowledge Vault" imgStyle={{ width: '100%', height: 'auto' }} placeholder="blurred" loading="lazy" />
        </Link>
      </Container>
      <Container
        disableGutters
        sx={{
          display: 'none', // hidden
          flexDirection: ['column', 'row', 'row', 'row', 'row'],
          marginTop: ['0px', '22px', '32px', '32px', '32px'],
        }}
      >
        <Link
          href="/archive"
          sx={{
            borderRadius: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '581px',
            margin: ['16px 0', '0 11px 0 0', '0 16px 0 0', '0 16px 0 0', '0 16px 0 0'],
          }}
        >
          <StaticImage src='../img/communityArchive.png' alt="Community Article" imgStyle={{ width: '100%', height: 'auto' }} placeholder="blurred" loading="lazy" />
        </Link>
        <Link
            href="/videos"
            sx={{
                borderRadius: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '581px',
                margin: ['16px 0', '0 0 0 11px', '0 0 0 16px', '0 0 0 16px', '0 0 0 16px'],
              }}
          >
            <StaticImage src='../img/videoTutorials.png' alt="Video Tutorials" imgStyle={{ width: '100%', height: 'auto' }} placeholder="blurred" loading="lazy" />
          </Link>
      </Container>
    </ContentContainer>
  );
}

export default HomeInfoBoxes;
