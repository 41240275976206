import React from 'react';
import { Typography } from '@mui/material';
import {StaticImage} from 'gatsby-plugin-image';
import ContentContainer from '../styles/content-container';

function Hero() {
  const text = "Find out what's new in the Realm";
  return (
    <ContentContainer
      sx={{ width: ['100%', '95%', '83%', '83%', '83%'], marginBottom: ['32px', '32px', '48px', '64px', '64px'] }}
      disableGutters
    >
      <Typography
        variant="h1"
        sx={{ marginBottom: '48px', textAlign: 'center', fontSize: ['36px', '36px', '48px', '60px', '60px'] }}
      >
        {text}
      </Typography>
      <StaticImage src='../img/Citadel.png' alt="Hero Citadel" style={{ maxWidth: '100%' }} placeholder="blurred" loading="eager" />
    </ContentContainer>
  );
}

export default Hero;
