import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import { SEO } from '../components/Seo';
// import SubscribeForm from '../components/SubscribeForm';
import HomeInfoBoxes from '../components/HomeInfoBoxes';
import Featured from '../components/Featured';
import HomeLinkBoxes from '../components/HomeLinkBoxes';

export default function Home() {
  return (
    <Layout>
      <Hero />
      <HomeInfoBoxes />
      <Featured />
      <HomeLinkBoxes />
      {/* <SubscribeForm /> */}
    </Layout>
  );
}

export const Head = () => (
  <SEO />
)
